import type { NuxtError } from '#app'
import { useQueryClient, useQuery, useMutation } from '@tanstack/vue-query'
import type { ApiErrorClass } from '~/types/apiError'
import type {
  CompanyCreator,
  Creator,
  CreatorActivities,
  CreatorBalance,
  UpdateCompanyCreator,
  UpdateCreator,
  UpdateCreatorPassword
} from '~/types/creator'

export const useStoreCreator = defineStore('creator', () => {
  const { isAuthenticated } = storeToRefs(useStoreAuth())

  const { logout } = useStoreAuth()

  const getCompanyCreator = (companyId: number) => {
    return useQuery({
      queryKey: ['companyCreator', companyId],
      queryFn: async () => {
        try {
          const creators: CompanyCreator[] = await useNuxtApp().$api(
            `/api/creator/companies-creators/?company_id=${companyId}`
          )
          return creators.length > 0 ? creators[0] : null
        } catch (error) {
          const { statusCode } = error as NuxtError
          if (statusCode === 404) {
            logout()
          } else {
            throw createError({ statusCode })
          }
        }
      },
      enabled: isAuthenticated.value,
      placeholderData: null,
      staleTime: 1800000 // 30 minutes
    })
  }

  const getCreatorBalance = (companyId: number) => {
    return useQuery({
      queryKey: ['fetchCreatorBalance', companyId],
      queryFn: async () => {
        try {
          const balance: CreatorBalance = await useNuxtApp().$api(
            `/api/creator/point-transactions/?company_id=${companyId}`
          )
          return balance.current_balance || 0
        } catch (error) {
          return 0
        }
      },
      enabled: isAuthenticated.value,
      placeholderData: 0,
      staleTime: 0
    })
  }

  const getCreatorActivities = (companyId: number, limit: number) => {
    return useQuery({
      queryKey: ['fetchCreatorActivities', companyId],
      queryFn: async () => {
        return (await useNuxtApp().$api(`/api/creator/point-transactions/`, {
          params: {
            company_id: companyId,
            limit
          }
        })) as CreatorActivities
      },
      staleTime: 0
    })
  }

  const hasToWaitInstagramAccountLinked = ref(false)

  const fetchCreator = () => {
    return useQuery({
      queryKey: ['fetchCreator'],
      queryFn: async () => {
        const res: Creator = await useNuxtApp().$api('/api/creator/me/')
        if (hasToWaitInstagramAccountLinked.value && res.insta_account.is_private === null) {
          throw new Error()
        }
        return res ?? null
      },
      // 20 attempts max and 2.5 seconds delay between each
      retry: (failureCount) => (hasToWaitInstagramAccountLinked.value ? failureCount < 19 : failureCount < 2),
      retryDelay: () => (hasToWaitInstagramAccountLinked.value ? 2500 : 1000),
      enabled: isAuthenticated.value
    })
  }

  const setHasToWaitInstagramLinked = (value: boolean) => {
    hasToWaitInstagramAccountLinked.value = value
  }

  const queryClient = useQueryClient()

  const updateCreator = () => {
    return useMutation({
      mutationFn: async (updateData: UpdateCreator) => {
        await useNuxtApp().$api(`api/creator/${updateData.creatorId}/`, {
          method: 'PATCH',
          body: {
            first_name: updateData.firstname,
            email: updateData.email,
            last_name: updateData.lastname,
            gender: updateData.gender,
            phone_number: updateData.phoneNumber,
            birthdate: updateData.birthdate,
            profil: updateData.profil
          }
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['fetchCreator'] })
      }
    })
  }

  const updateCompanyCreator = () => {
    return useMutation({
      mutationFn: async (updateData: UpdateCompanyCreator) => {
        await useNuxtApp().$api(`api/creator/companies-creators/${updateData.creatorId}/`, {
          method: 'PATCH',
          body: {
            optin_missions: updateData.optinMissions,
            optin_rewards: updateData.optinRewards
          }
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['companyCreator'] })
      }
    })
  }

  const updatePassword = async (updateData: UpdateCreatorPassword) => {
    const { creatorId, currentPassword, newPassword, confirmPassword } = updateData
    return await useNuxtApp().$api(`/api/creator/${creatorId}/update_password/`, {
      method: 'PATCH',
      body: {
        old_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword
      }
    })
  }

  const getCreatorMailSubscriptionsExternal = (companyId: number, creatorId: string, token: string) => {
    return useQuery({
      queryKey: ['creatorSubscriptions'],
      queryFn: async () => {
        const creators: CompanyCreator[] = await useNuxtApp().$api(
          `/api/creator/companies-creators/unsubscribe-optin/`,
          {
            method: 'GET',
            params: {
              company_id: companyId,
              creator_id: creatorId,
              token
            }
          }
        )
        return creators.length > 0 ? creators[0] : null
      },
      staleTime: 0,
      enabled: false
    })
  }

  const updateCreatorMailSubscriptionsExternal = () => {
    return useMutation({
      mutationFn: async ({
        companyId,
        token,
        updateData
      }: {
        companyId: number
        token: string
        updateData: UpdateCompanyCreator
      }) => {
        await useNuxtApp().$api(`/api/creator/companies-creators/unsubscribe-optin/`, {
          method: 'PATCH',
          params: {
            company_id: companyId,
            creator_id: updateData.creatorId,
            token
          },
          body: {
            optin_missions: updateData.optinMissions,
            optin_rewards: updateData.optinRewards
          }
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['creatorSubscriptions'] })
      },
      onError: (error: ApiErrorClass) => {
        return error
      }
    })
  }

  return {
    getCompanyCreator,
    getCreatorBalance,
    fetchCreator,
    updateCreator,
    updatePassword,
    updateCompanyCreator,
    setHasToWaitInstagramLinked,
    getCreatorActivities,
    getCreatorMailSubscriptionsExternal,
    updateCreatorMailSubscriptionsExternal
  }
})
